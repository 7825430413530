import { HTTP } from '.';
import buildQuery from '@/helpers/url';
export default new class SettingsService {
  constructor() {}

  getVenueReportValues() {
    return HTTP.get(
      '/v1/settings/venue-report-values');
  }

  saveVenueReportValues(data) {
    return HTTP.put(
      '/v1/settings/venue-report-values', data);
  }

  getDealPipelines(country) {
    let params = {country};
    return HTTP.get('/v1/settings/deal-pipelines?' + buildQuery(params));
  }
}();

