<template>
  <div class="content">
    <div class="header d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-info"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="display-3 text-white">Venue Report Values</h1>
          </div>
        </div>
      </div>
    </div>

    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta && $route.meta.name ? $route.meta.name : $route.name }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-8">
          <template>
            <card>
              <div slot="header" class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0" id="lead_details_heading">
                    Venue Report Values
                  </h3>
                </div>
                <div class="col-4 text-right">
                  <a href="javascript:"  class="btn btn-sm btn-primary lead-save" @click="save">Save</a>
                </div>
              </div>
              <form v-loading="loading" id="leadinformation">
                <div>
                  <h6 class="heading-small text-muted mb-2">
                    Marketing Costs
                  </h6>
                  <div>
                    <div class="row">
                      <div class="col-lg-6">
                        <base-input
                          type="text"
                          label="AU"
                          id="lead_information_first_name"
                          prependIcon="fa-solid fa-dollar-sign"
                          placeholder="AU"
                          v-model="form.venue_report_marketing_cost_au"
                        >
                        </base-input>
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          type="text"
                          label="UK"
                          prependIcon="fa-solid fa-sterling-sign"
                          id="lead_information_last_name"
                          placeholder="UK"
                          v-model="form.venue_report_marketing_cost_uk"
                        >
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h6 class="heading-small text-muted mb-2">
                    Staff Costs
                  </h6>
                  <div>
                    <div class="row">
                      <div class="col-lg-6">
                        <base-input
                          type="text"
                          label="Hourly Rate (AU)"
                          prependIcon="fa-solid fa-dollar-sign"
                          id="lead_information_first_name"
                          placeholder="AU"
                          v-model="form.venue_report_staff_hourly_rate_au"
                        >
                        </base-input>
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          type="text"
                          label="Hourly Rate (UK)"
                          id="lead_information_last_name"
                          prependIcon="fa-solid fa-sterling-sign"
                          placeholder="UK"
                          v-model="form.venue_report_staff_hourly_rate_uk"
                        >
                        </base-input>
                      </div>
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-lg-6">
                          <base-input
                            type="text"
                            label="Conversion Rate"
                            id="lead_information_first_name"
                            prependIcon="fa-solid fa-percent"
                            placeholder="Conversion Rate"
                            v-model="venue_report_conversion_rate"
                          >
                          </base-input>
                        </div>
                        <div class="col-lg-6">
                          <base-input
                            type="text"
                            label="Time Per Enquiry"
                            id="lead_information_last_name"
                            placeholder="Time Per Enquiry"
                            v-model="form.venue_report_time_per_enquiry"
                          >
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SettingsService from '@/services/settings.service';
export default {
  data : function() {
    return {
      loading: false,
      form: {
        venue_report_marketing_cost_au: '',
        venue_report_marketing_cost_uk: '',
        venue_report_staff_hourly_rate_au: '',
        venue_report_staff_hourly_rate_uk: '',
        venue_report_conversion_rate: '',
        venue_report_time_per_enquiry: '',
      },
      venue_report_conversion_rate: 0,
    };
  },
  async mounted() {
    let values = await SettingsService.getVenueReportValues();
    this.form = values;
    this.venue_report_conversion_rate = this.form.venue_report_conversion_rate * 100;
  },
  methods: {
    async save() {
      this.loading = true;
      this.form.venue_report_conversion_rate = this.venue_report_conversion_rate / 100;
      let values = await SettingsService.saveVenueReportValues(this.form);
      this.loading = false;
    },
  },
};
</script>
